<template>
    <div v-if="comments" class="slider slider-comments">
      <div class="slides" :style="{ 'left': '-' + (100 * (currentSlideIndex - 1)) + '%' }">
        <div class="slide" v-for="(comment, index) in comments" :key="index">
          <p class="name">{{ comment.author_name }}</p>
          <p class="date">{{ comment.date }}</p>
          <p class="text">“{{ comment.comment }}”</p>
        </div>
      </div>
      <div class="nav">
        <span class="item prev" @click="prevSlide">
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
            <path d="M13.5 0C20.9558 0 27 6.04415 27 13.5C27 20.9558 20.9558 27 13.5 27C6.04415 27 0 20.9558 0 13.5C0 6.04415 6.04415 0 13.5 0ZM10.3722 14.0966L14.169 17.8935C14.3338 18.0583 14.5497 18.1407 14.7656 18.1407C15.5106 18.1407 15.8952 17.2333 15.3622 16.7003L12.162 13.5001L15.3622 10.2998C15.6917 9.97028 15.6917 9.43603 15.3622 9.10654C15.0327 8.77706 14.4985 8.77706 14.169 9.10654L10.3721 12.9034C10.0426 13.233 10.0426 13.7672 10.3722 14.0966Z" fill="#04B67D"/>
          </svg>
        </span>
        <span class="count"><span>{{ currentSlideIndex }}/</span>{{ comments.length }}</span>
        <span class="item next" @click="nextSlide">
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
            <path d="M13.5 0C6.04415 0 0 6.04415 0 13.5C0 20.9558 6.04415 27 13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04415 20.9558 0 13.5 0ZM16.6278 14.0966L12.831 17.8935C12.6662 18.0583 12.4503 18.1407 12.2344 18.1407C11.4894 18.1407 11.1048 17.2333 11.6378 16.7003L14.838 13.5001L11.6378 10.2998C11.3083 9.97028 11.3083 9.43603 11.6378 9.10654C11.9673 8.77706 12.5015 8.77706 12.831 9.10654L16.6279 12.9034C16.9574 13.233 16.9574 13.7672 16.6278 14.0966Z" fill="#04B67D"/>
          </svg>
        </span>
      </div>
    </div>
</template>

<script>
export default {
  name: "slider-comments",
  props: {
    comments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentSlideIndex: 1
    }
  },
  methods: {
    prevSlide() {
      if(this.currentSlideIndex > 1) {
        this.currentSlideIndex--
      }
    },
    nextSlide() {
      if(this.currentSlideIndex < this.comments.length) {
        this.currentSlideIndex++
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.slider-comments
  position: relative
  border-radius: 5px
  overflow: hidden
  max-width: 100%
  border: 1px solid #04B67D
  margin-top: 20px
  .slides
    position: relative
    display: flex
    transition: all ease .3s
    .slide
      min-width: 100%
      padding: 13px 15px 5px 15px
      .name
        font-weight: 700
        margin-bottom: 5px
      .date
        font-size: 12px
        font-weight: 300
        margin-bottom: 5px
      .text
        font-style: italic
        font-size: 15px
        line-height: 1.4
        margin: 0
  .nav
    display: flex
    align-items: center
    justify-content: center
    gap: 0 10px
    margin-bottom: 10px
  .item
    line-height: 0
    cursor: pointer
  .count
    font-size: 12px
    span
      font-size: 16px
      font-weight: 600
</style>